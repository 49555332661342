import {
    Component,
    Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "delete-all-data-confirmation",
    templateUrl: "angular-blocworx/components/deleteAllData/delete-all-data-confirmation.html",
})

export class DeleteAllDataConfirmation {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteAllDataConfirmation>,
    ) {

    }

    async ngOnInit(): Promise<void> {
    }

    /**
     * Closes the popup without sending a result back to the delete-all-data component
     */
    cancel() {
        this.dialogRef.close();
    }

    /**
     * Sends a result back to the delete-all-data component which will call the deleteAllData() function
     */
    confirm() {
        this.dialogRef.close(true);
    }

}
