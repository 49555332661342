import {Component, Inject, OnInit, ViewChildren, Input, ElementRef, QueryList } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";


@Component({
    selector: 'html-field-in-form',
    templateUrl: 'angular-blocworx/components/fields/html/templates/in-form.html',
    styleUrls: ['angular-blocworx/components/fields/html/html.component.scss'],
})

export class HtmlFieldInForm implements OnInit {

    @Input() field;
    @Input() scanStation;
    htmlContent: SafeHtml;

    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) {

    }

    ngOnInit() {
        this.scanStation.domIndexes[this.field.field_slug] = this.field.fieldIndex;
        this.htmlContent = this.sanitizeContent(this.field.htmlContent);
    }

    /**
     *
     * Allows html to be displayed on screen
     *
     */

    public sanitizeContent(content: string): SafeHtml {
        if (!content) {
            return '';
        }
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

}
