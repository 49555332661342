import { Inject, Injectable } from "@angular/core";
import { Configuration } from "@/angular-blocworx/components/configuration/services/configuration.service";

@Injectable({
    providedIn: 'root'
})

export class HtmlFieldService {
    constructor(@Inject(Configuration) private Configuration: Configuration) {
    }

    /**
     * Returns the initial Parameters for this field.
     */
    getInitialParams() {

        try
        {
            let initialParams = {}

            return initialParams;

        } catch (e) {
            console.log(e);
        }


    }

    /**
     * Returns the configuration object for the field component.
     */
    htmlFieldDirective() {

        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/html/field.html?v=${version}&rand=${random}`;

        return {
            controller: "FieldController",
            restrict: "A",
            templateUrl: templateUrl
        };

    }

    /**
     * Returns the configuration object for the print component.
     */
    htmlFieldPrintDirective() {

        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/html/print.html?v=${version}&rand=${random}`;

        return {
            controller: "FieldController",
            restrict: "A",
            templateUrl: templateUrl
        };

    }


    /**
     * Returns the configuration object for the build/edit component.
     */
    htmlFieldBuild() {

        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/html/build-edit.html?v=${version}&rand=${random}`;

        return {
            controller: "FieldController",
            restrict: "A",
            templateUrl: templateUrl
        };

    }

}
