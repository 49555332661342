import { Component, OnInit, Injectable, Inject } from "@angular/core";
import { TemplateListService } from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "template-list-edit",
    templateUrl: "angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list-edit.html",
})

@Injectable({
    providedIn: "root",
})

export class TemplateListEdit implements OnInit {

    selectedTemplate: string;
    newTemplateName: string;
    templateController: any;
    scanStation: any;
    primaryKey: any;
    outputFileValue: any;
    newOutputFileName: any;
    file: File | null = null;
    fileNameForTemplate: any;


    constructor(
        @Inject(TemplateListService) protected TemplateListService: TemplateListService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<TemplateListEdit>,
    ) {
        this.selectedTemplate = data.selectedTemplate;
        this.newTemplateName = data.newTemplateName;
        this.templateController = data.templateController;
        this.scanStation = data.scanStation;
        this.primaryKey = data.primaryKey;
        this.outputFileValue = data.outputFileValue;
        this.fileNameForTemplate = data.fileNameForTemplate;
        this.newOutputFileName = data.newOutputFileName;
    }


    async ngOnInit(): Promise<void> {
        this.newTemplateName = this.selectedTemplate;
        this.templateController.outputFileName = this.data.outputFileValue;
        this.removeDatePrefix();
    }

    /**
     * Removes date from the template name displayed on screen to the user
     */
    removeDatePrefix(): void {
        const pattern = /^\d{8}/;
        this.fileNameForTemplate = this.fileNameForTemplate.replace(pattern, '').trim();
    }

    /**
     *  Updates the template details when the user clicks the "Update" button
     */
    async updateTemplate() {
        const newOutputFileName = this.templateController.outputFileName.includes("null")
            ? this.templateController.outputFileName.replace(/null/gi, "").trim()
            : this.templateController.outputFileName;

        const formData = new FormData();
        if (this.file) {
            formData.append("file", this.file, this.file.name);
        }
        formData.append("primaryKey", this.primaryKey);
        formData.append("templateOldName", this.selectedTemplate);
        formData.append("newTemplateName", this.newTemplateName);
        formData.append("outputFilename", newOutputFileName);
        formData.append("scanStationId", this.scanStation.id);

        await this.TemplateListService.updateTemplate(formData);

        this.templateController.templateFileList = await this.TemplateListService.loadScanStationTemplates(this.scanStation.id);

    }

    /**
     *
     * Updates the template file
     *
     * @param event
     */
    onFileSelected(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            this.file = file;
            this.fileNameForTemplate = this.file.name;
        }
    }

    /**
     *
     * Updates the template name
     *
     * @param selectedTemplate
     */
    updateSelectTemplate(selectedTemplate: any) {
        if (!this.newTemplateName || this.newTemplateName.trim() === "") {
            return;
        } else {
            this.selectedTemplate = selectedTemplate;
        }
    }

    /**
     *
     * Updates the output file name
     *
     * @param outPutFileName
     */
    updateOutputFile(outPutFileName: any) {
        this.newOutputFileName = outPutFileName;
    }


    /**
     *
     * Updates the file name for the template
     */
    updateFile() {
        this.templateController.exported_fileName = this.fileNameForTemplate;
    }

    /**
     * Updates variables / template details after update is clicked
     */
    confirm() {
        this.templateController.showFieldSlugsForTemplateManager = false;
        if (!this.newTemplateName || this.newTemplateName.trim() === "") {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close({ newTemplateName: this.newTemplateName, newOutputFileName: this.newOutputFileName,
                fileNameForTemplate: this.fileNameForTemplate});

        }

    }

    /**
     * Closes the dialog popup and does not save any changes
     */
    cancel() {
        this.templateController.showFieldSlugsForTemplateManager = false;
        this.dialogRef.close();
    }

}






