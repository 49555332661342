import {
    Component,
    Inject,
    Input,
} from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { DeleteAllDataConfirmation } from "@/angular-blocworx/components/deleteAllData/delete-all-data-confirmation.component";
import { OverlayContainer } from "@angular/cdk/overlay";
import { DeleteAllDataService } from "@/angular-blocworx/components/deleteAllData/delete-all-data.service";

@Component({
    selector: "delete-all-data",
    templateUrl: "angular-blocworx/components/deleteAllData/delete-all-data.html",
})

export class DeleteAllData {
    @Input() main;
    @Input() scanStation;

    scanStationId: string;
    showDeleteAllData: boolean;
    confirmationPhrase: string;
    password: any;

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer,
        @Inject(DeleteAllDataService) private DeleteAllDataService: DeleteAllDataService,
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.showDeleteAllData = false;
        this.scanStationId = this.scanStation.id;
    }

    /**
     *  Opens a confirmation dialog when the user wants to proceed with deleting all data
     *  It opens a dialog and listens for the result, if user confirms, it then runs deleteAllData()
     */
    openDialog() {
        const dialogRef = this.dialog.open(DeleteAllDataConfirmation, {
            data: {
                // You can pass data here to use in the popup
            },
        });

        this.overlayContainer.getContainerElement().classList.add("blocworx-popup");

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteAllData();
            }
            this.overlayContainer.getContainerElement().classList.remove("blocworx-popup");
        });
    }

    /**
     *  Deletes all data upon successful confirmation
     */
    async deleteAllData(): Promise<void> {
        try {

            await this.DeleteAllDataService.deleteAllData(
                this.scanStationId,
                this.password,
                this.confirmationPhrase,
                this.scanStation.userData.id
            );

            alert("Deleted all data successfully");

            this.confirmationPhrase = "";
            this.password = "";

            // Reloads the records displayed after deletion
            await this.scanStation.getData(this.scanStationId);

        } catch (error) {
            this.showAlert(error.error?.error || "An unknown error occurred.");
        }
    }


    /**
     *
     * @param message
     */
    showAlert(message: string): void {
        alert(message);
    }

    /**
     * Shows delete data dialog which requires user to enter phrase and password etc
     */
    showDeleteAllDataButton = () => {
        this.showDeleteAllData = true;
    }

    /**
     * Closes the delete data dialog which requires user to enter phrase and password etc
     */
    cancelWithXButton = () => {
        this.showDeleteAllData = false;
    }

}
