import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class TimeoutService {

    constructor() { }

    validateTimeoutSettings(timeOut: number): boolean {
        if (timeOut <= 0) {
            alert("Invalid value entered. The minimum timeout entered must be greater than 0");
            return false;
        }
        return true;
    }

}
