import {
    Component, Inject,  Input, Injectable, OnInit, Optional, Injector, SimpleChanges
} from '@angular/core';

import { AppSettingService } from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { TimeoutService } from "@/angular-blocworx/components/admin/app-setting/timeout/timeout.service";

@Component({
    selector: 'timeout-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/timeout/timeout-app-setting.html',

})

@Injectable()

export class TimeoutAppSetting {


    @Input() inactivityTimeoutData;
    @Input() main;


    timeOut: any;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
        @Inject(TimeoutService) private timeoutService: TimeoutService,
    ) {

    }


    ngOnInit(): void {

    }


    async setAppSetting(settingKey, settingValue) {
        const validated = this.timeoutService.validateTimeoutSettings(this.timeOut);

        if (validated) {
            let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
            await this.appSettingService.setAppSetting(cartolyticsCustomerId, settingKey, settingValue);
            this.showAlert();
        }
    }

    showAlert() {
        alert("Inactivity timeout set to " + this.timeOut + " minutes");
    }
}
