import { Injectable, Inject } from "@angular/core";
import { Configuration } from "@/angular-blocworx/components/configuration/services/configuration.service";
import { RequestService } from "@/angular-blocworx/components/configuration/services/request.service";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})

export class TemplateListService {

    apiUrl = this.configuration.getApiUrl();
    apiBase: any;

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService,
    ) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
    }


    /**
     *
     * Post request to download a template file
     *
     * @param scanStationId
     * @param chosenTemplateFile
     */
    public templateDownloadMock = async (scanStationId, chosenTemplateFile): Promise<Blob> => {

        let headers = this.request.getHeadersForFormData();

        let params = { scanStationId, chosenTemplateFile };

        let response = this.http.post(`${this.apiUrl}template-download-mock`, params, {
            headers,
            responseType: 'blob'
        });

        return lastValueFrom(response);
    };

    /**
     *
     * Get request to delete a template file
     *
     * @param templateId
     */
    public deleteTemplate = async (templateId): Promise<any> => {

        let headers = this.request.getHeaders();

        let params = { templateId };

        let response = this.http.get(`${this.apiUrl}delete-template`, { params, headers });

        return lastValueFrom(response);
    };

    /**
     *
     * Get request to get a list of all the templates the user has uploaded
     *
     * @param scanStationId
     */
    public loadScanStationTemplates = async (scanStationId): Promise<any> => {

        let headers = this.request.getHeaders();

        let params = { scanStationId };

        let response = this.http.get(`${this.apiUrl}load-scan-station-templates`, { params, headers });

        return lastValueFrom(response);
    };

    /**
     *
     * Post request to update the details of a template
     *
     * @param formData
     */
    public updateTemplate = async (formData: FormData): Promise<any> => {

        let headers = this.request.getHeadersForFormData();

        let response = this.http.post(`${this.apiUrl}update-template`, formData, { headers });

        return lastValueFrom(response);
    };

}


