import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class LoginAttemptsService {
    constructor() { }

    validateLoginAttemptSettings(loginInput: number, lockOutInput: number): boolean {
        if (loginInput <= 0 || lockOutInput <= 0) {
            return false;
        }
        return true;
    }

}



