import {Injectable, Inject} from '@angular/core';
import angular from "angular";
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from "rxjs";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AppSettingService {

    protected apiUrl: string;

    constructor(

        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {

        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
    }


    /**
     * This function adds the app setting to the database
     * @param cartolyticsCustomerId
     * @param key
     * @param value
     */
    public setAppSetting = (cartolyticsCustomerId, key, value): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            cartolyticsCustomerId,
            key,
            value,
        }
        const response = this.http.post(`${this.apiUrl}set-app-settings`, body, {headers});
        return lastValueFrom(response)
    }

    /**
     * This function gets the app setting from local storage
     * @param requestedSettingKey
     */


    loadSingleAppSetting (requestedSettingKey): string {
        try {

            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

            if(appSettingParsed != null && appSettingParsed.data != null) {
                for (let setting in appSettingParsed.data) {
                    if (appSettingParsed.data.hasOwnProperty(setting)) {

                        if (appSettingParsed.data[setting].setting_key == requestedSettingKey) {

                            return appSettingParsed.data[setting].setting_value
                        }
                    }
                }
            }

            return '';
        } catch (error) {
            console.log(error);
        }

    }

    // GET request to backend
    public getAppSettings = async (cartolyticsCustomerId: string) : Promise<any> => {

        let headers = this.request.getHeaders();

        const params = { cartolyticsCustomerId };

        let response = this.http.get(
            `${this.apiUrl}get-app-settings`,
            {params, headers}
        );


        return lastValueFrom(response)

    }





}
