import {Injectable, Inject} from '@angular/core';
import angular from "angular";
import { downgradeInjectable } from '@angular/upgrade/static';
import {BaseFieldService} from "@/angular-blocworx/components/fields/BaseFieldService";
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from "rxjs";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})

export class CalendarResourcePlanningService extends BaseFieldService {

    private _currentDay: moment.Moment;
    private _currentWeek: moment.Moment;
    private _currentMonth: Date;
    private getDataForCalendarFieldFromService: Function;
    private componentInstances: { [key: string]: any } = {};


    constructor(

        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {

        super(http, configuration, request);
        this._currentDay = moment().locale('en-gb');
        this._currentWeek = moment().locale('en-gb');
        this._currentMonth = new Date();
    }

    setCurrentDay(day: moment.Moment) {

        this._currentDay = day;
    }

    getCurrentDay(): moment.Moment {
        return this._currentDay;
    }

    setCurrentWeek(week: moment.Moment) {

        this._currentWeek = week;
    }

    getCurrentWeek(): moment.Moment {
        return this._currentWeek;
    }

    setCurrentMonth(month: Date) {
        this._currentMonth = month;
    }

    getCurrentMonth(): Date {
        return this._currentMonth;
    }


    getInitialParams(): object {

        return {
            'calendarResourcePlanningViewType': {
                'validation': {
                    'required': false,
                }
            },
            'calendarAreaDetailsBlocList': {
                'subParameters': {
                    'jobId': {
                        'validation': {
                            'required': false
                        }
                    }
                },
                'validation': {
                    'required': false,
                }
            },
            'sendExtraDataMatchingFields': {
                'validation': {
                    'required': false
                }
            },
            'filterDataLookUp': {
                'validation': {
                    'required': false
                }
            },
            'showLoadDataButton': {
                'validation': {
                    'required': false
                }
            },
            'calendarAreaBlocDetails': {
                'subParameters': {
                    'stationId': {
                        'validation': {
                            'required': true,
                            'validationResponse': 'Please choose a Bloc to look up',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'startingDateTimeFieldId': {
                        'validation': {
                            'required': false
                        }
                    },
                    'endingDateTimeFieldId': {
                        'validation': {
                            'required': false
                        }
                    },
                    'calendarItemLabelField': {
                        'validation': {
                            'required': false
                        }
                    },
                    'calendarUpdatableField': {
                        'validation': {
                            'required': false
                        }
                    },
                    'calendarLeftSideMatchingField': {
                        'validation': {
                            'required': false
                        }
                    },
                    'calendarRecordColourField': {
                        'validation': {
                            'required': false
                        }
                    }
                },
                'validation': {
                    'required': true,
                    'validationResponse': 'Please select a bloc and a field to look up',
                    'requiredType': 'listMustHaveOne'
                }
            },
            'localFieldMatchingFieldPairs': {
                'validation': {
                    'required': false,
                }
            },
            'dropdownFromStationReverseOrder': {
                'validation': {
                    'required': false,
                }
            },
            'localDataListFieldId': {
                'validation': {
                    'required': false,
                }
            },
            'calendarResourcePlanningApplyBlocInitialisation': {
                'validation': {
                    'required': false,
                }
            },
            'calendarResourcePlanningApplyFieldActions': {
                'validation': {
                    'required': false,
                }
            },
            'calendarResourcePlanningPreSubmitFeatures': {
                'validation': {
                    'required': false,
                }
            },
            'enableZoomPinchOnMobileTablet': {
                'validation': {
                    'required': false,
                }
            },
            'showCountsOfRecordsInsteadOfIndividual': {
                'validation': {
                    'required': false,
                }
            },
            'makeCalendarResponsive': {
                'validation': {
                    'required': false,
                }
            },
            'automaticallyMakeFullScreenOnLoad': {
                'validation': {
                    'required': false,
                }
            },
            'chooseColourForCountData': {
                'validation': {
                    'required': false,
                }
            },
            'showSingleDayWithHoursViewOnDateClick': {
                'validation': {
                    'required': false,
                }
            },
            'startTimeCustomFormat': {
                'validation': {
                    'required': false,
                }
            },
            'finishTimeCustomFormat': {
                'validation': {
                    'required': false,
                }
            },
            'showBlocPopUpOnEntryClick': {
                'validation': {
                    'required': false,
                }
            },
            'itemHeight': {
                'validation': {
                    'required': false,
                }
            },
            'showBlocPopUpOnEntryClickAllowEdit': {
                'validation': {
                    'required': false,
                }
            }
            ,
            'showBlocPopUpOnEntryClickForAddingRecords': {
                'validation': {
                    'required': false,
                }
            }
        };
    }

    // TODO Can be generic to editing fields
    addOrRemoveLocalAndRemoteMatchingFieldPairs (localFieldMatchingFieldPairs, action, localField, remoteField, index = null) {

        if((localField == '' || typeof localField == 'undefined') && action == 'add') {
            alert('Please Select a local Field');
            return localFieldMatchingFieldPairs;
        }

        if((remoteField == '' || typeof remoteField == 'undefined') && action == 'add') {
            alert('Please Select a remote field it must match');
            return localFieldMatchingFieldPairs;
        }

        if( localFieldMatchingFieldPairs == null || localFieldMatchingFieldPairs == "") {
            localFieldMatchingFieldPairs = {};
        }

        if(action == 'add') {
            localFieldMatchingFieldPairs[Object.keys(localFieldMatchingFieldPairs).length] = {
                'localFieldId' : localField,
                'remoteFieldId' : remoteField
            };

        } else {
            delete localFieldMatchingFieldPairs[index];
            // build new object with fresh keys to keep it in sync
            let fieldListForLoop = localFieldMatchingFieldPairs;
            localFieldMatchingFieldPairs = {};
            let i: number = 0;
            angular.forEach(fieldListForLoop, function(value) {
                localFieldMatchingFieldPairs[i] = value;
                i++;
            });
        }

        return localFieldMatchingFieldPairs;

    }

    getDataForCalendarField = async (fieldId, dateForCalendarData, dataFilteringData, calendarResourcePlanningViewType:any = false) : Promise<any> =>{

        let apiBase:string = this.evervaultEnabledApiUrl + 'get-data-for-calendar-field';

        let params = new HttpParams().set('fieldId', fieldId).set('dateForCalendarData',dateForCalendarData);

        if(calendarResourcePlanningViewType != false) {
            params = params.set('calendarResourcePlanningViewType',calendarResourcePlanningViewType);
        }

        if(dataFilteringData.stationID != "") {
            params = params.set('dataFilteringData',JSON.stringify(dataFilteringData))
        }

        let headers = this.request.getHeaders();

        const response = this.http.get(apiBase, {params, headers});

        return lastValueFrom(response);
    }

    public changeEndDateValueFromCalendar = (fieldId, cartolyticsEntryRowId, newDate, calendarResourcePlanningViewType: any = false): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            fieldId,
            cartolyticsEntryRowId,
            newDate,
            calendarResourcePlanningViewType
        }
        const response = this.http.post(`${this.apiUrl}change-end-date-value-from-calendar`, body, {headers});
        return lastValueFrom(response)
    }

    public moveRecordWithinCalendar = (fieldId, cartolyticsEntryRowId, startDate, numberOfDays, leftValue, calendarResourcePlanningViewType: any = false): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            fieldId,
            cartolyticsEntryRowId,
            startDate,
            numberOfDays,
            leftValue,
            calendarResourcePlanningViewType
        }
        const response = this.http.post(`${this.apiUrl}move-record-within-calendar`, body, {headers});
        return lastValueFrom(response)
    }

    public addNewOrUpdateRecordFromCalendar = (fieldId, startDate, labelValue, leftValue, extraDataToSend, endDate = null, cartolyticsEntryRowId = null, calendarResourcePlanningViewType:any = false): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            fieldId,
            startDate,
            labelValue,
            leftValue,
            extraDataToSend,
            endDate,
            cartolyticsEntryRowId,
            calendarResourcePlanningViewType
        }

        const response = this.http.post(`${this.evervaultEnabledApiUrl}add-new-or-update-record-from-calendar`, body, {headers});
        return lastValueFrom(response)
    }

    public updateRecordValueFromCalendar = (fieldId, cartolyticsEntryRowId, calendarUpdatableFieldValue, calendarResourcePlanningViewType: any = false): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            fieldId,
            cartolyticsEntryRowId,
            calendarUpdatableFieldValue,
            calendarResourcePlanningViewType
        }
        const response = this.http.post(`${this.evervaultEnabledApiUrl}update-record-value-from-calendar`, body, {headers});
        return lastValueFrom(response)
    }

    deleteRecord = async (entryID) : Promise<any> =>{

        let apiBase:string = this.apiUrl + 'delete-data';

        let params = new HttpParams().set('entryID',entryID);

        let headers = this.request.getHeaders();

        const response = this.http.delete(apiBase, {params, headers});

        return lastValueFrom(response);
    }



    // Register a component instance using a unique identifier
    public registerComponentInstance(instanceId: string, instance: any) {
        this.componentInstances[instanceId] = instance;
    }

    // Deregister a component instance when it is destroyed
    public deregisterComponentInstance(instanceId: string) {
        delete this.componentInstances[instanceId];
    }

    // Trigger getDataForCalendarField for a specific component based on sourceField.id
    public triggerGetDataForCalendarField(sourceField: any) {
        const componentInstance = this.componentInstances[sourceField.id];
        if (componentInstance) {
            componentInstance.field = sourceField; // Set field to sourceField
            componentInstance.getDataForCalendarField(
                componentInstance.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss')
            );
        } else {
            console.warn(`No component instance registered for sourceField.id: ${sourceField.id}`);
        }
    }


}

