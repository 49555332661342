import {Component, OnInit, Injectable, Optional, Inject, Input, Injector, SimpleChanges} from '@angular/core';
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";

@Component({
    selector: 'app-settings',
    templateUrl: 'angular-blocworx/components/admin/app-setting/app-setting.html',
    styleUrls: ['angular-blocworx/components/admin/app-setting/app-setting.scss']
})

@Injectable({
    providedIn: 'root'
})

export class AppSettings {

    appSettings: any;
    inactivityTimeoutData: any;
    noLoginAttemptsData: any;
    noLockOutTimeData: any;
    googleSignInData: any;
    googleCheckedData: any;
    languageData: any;
    languageNameData: any;
    legacyFeaturesData: any;
    schedulersData: any;
    evervaultUrlData: any;
    evervaultFieldSlugsData: any;
    evervaultTokenData: any;


    @Input() main;


    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService
    ) {
    }


    async ngOnInit() {
        await this.getAppSettings();
    }



    async getAppSettings(): Promise<void> {

        // Gets customer app-settings to appSettings array

        let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
        let appSettingResponse = await this.appSettingService.getAppSettings(cartolyticsCustomerId);
        this.appSettings = appSettingResponse.data;

        // Gets the value associated with keys within appSettings array

        let inactivityTimeoutData = this.appSettings.find(obj => obj.setting_key === 'inactivity_timeout');
        this.inactivityTimeoutData = inactivityTimeoutData.setting_value;

        let noLoginAttemptsData = this.appSettings.find(obj => obj.setting_key === 'max_login_attempts');
        this.noLoginAttemptsData = noLoginAttemptsData.setting_value;

        let noLockOutTimeData = this.appSettings.find(obj => obj.setting_key === 'login_attempts_timeout');
        this.noLockOutTimeData = noLockOutTimeData.setting_value;

        let googleSignInData = this.appSettings.find(obj => obj.setting_key === 'client_id');
        this.googleSignInData = googleSignInData.setting_value;

        let googleCheckedData = this.appSettings.find(obj => obj.setting_key === 'sign_in_with_google');
        this.googleCheckedData = googleCheckedData.setting_value;

        let legacyFeaturesData = this.appSettings.find(obj => obj.setting_key === 'show-legacy-features');
        this.legacyFeaturesData = legacyFeaturesData.setting_value;

        let schedulersData = this.appSettings.find(obj => obj.setting_key === 'turn-on-schedulers');
        this.schedulersData = schedulersData.setting_value;

        let evervaultUrlData = this.appSettings.find(obj => obj.setting_key === 'evervault_url');
        this.evervaultUrlData = evervaultUrlData.setting_value;

        let evervaultFieldSlugsData = this.appSettings.find(obj => obj.setting_key === 'evervault_field_slugs');
        this.evervaultFieldSlugsData = evervaultFieldSlugsData.setting_value;

        let evervaultTokenData = this.appSettings.find(obj => obj.setting_key === 'evervault_basic_token');
        this.evervaultTokenData = evervaultTokenData.setting_value;

        let languageData = this.appSettings.find(obj => obj.setting_key === 'language');
        this.languageData = languageData.setting_value;

        switch (this.languageData) {
            case 'de_DE':
                this.languageNameData = ' Deutsch';
                break;
            case 'fr_FR':
                this.languageNameData = ' Français';
                break;
            case 'hr':
                this.languageNameData = ' Hrvatski';
                break;
            case 'en_EN':
                this.languageNameData = ' English';
                break;
            case 'pt_BR':
                this.languageNameData = ' Português';
                break;
            case 'sv_SE':
                this.languageNameData = ' Svenska';
                break;
            case 'pl':
                this.languageNameData = ' Polski';
                break;
            default:
                this.languageNameData = ' English';
                break;
        }
    }
}
