import {Component, Inject, OnInit, Input } from '@angular/core';

@Component({
    selector: 'html-field-create-edit-popup',
    templateUrl: 'angular-blocworx/components/fields/html/templates/create-edit/popup.html',
    styleUrls: ['angular-blocworx/components/fields/html/html.component.scss'],
})

export class HtmlFieldCreateEditPopup implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {
            this.scanStation.activeField = 'html';
    }

}
