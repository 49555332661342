import {Component, Inject, OnInit, Input} from '@angular/core';


@Component({
    selector: 'html-field-create-edit-radio-button',
    templateUrl: 'angular-blocworx/components/fields/html/templates/create-edit/radio-button.html',
    styleUrls: ['angular-blocworx/components/fields/html/html.component.scss'],
})

export class HtmlFieldCreateEditRadioButton implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {

    }
}
