import {
    Component, Inject, Input, OnInit
} from '@angular/core';

import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { LoginAttemptsService } from "@/angular-blocworx/components/admin/app-setting/login-attempts/login-attempts.service";


@Component({
    selector: 'login-attempts-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/login-attempts/login-attempts-app-setting.html',

})

export class LoginAttemptsAppSetting {

    @Input() noLoginAttemptsData: any;
    @Input() noLockOutTimeData: any;
    @Input() main;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
        @Inject(LoginAttemptsService) private loginAttemptsService: LoginAttemptsService,
    ) {}

    loginInput: any;
    lockOutInput: any;

    ngOnInit(): void {

    }


    async setAppSetting() {
        const validated = this.loginAttemptsService.validateLoginAttemptSettings(this.loginInput, this.lockOutInput);

        if (validated) {
            let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
            await this.appSettingService.setAppSetting(cartolyticsCustomerId, 'max_login_attempts', this.loginInput);
            await this.appSettingService.setAppSetting(cartolyticsCustomerId, 'login_attempts_timeout', this.lockOutInput);
        }
        this.showAlert(validated);
    }

    showAlert(validated) {
        if (validated) {
            alert("Max login attempts set to " + this.loginInput + " , and lock out time to " + this.lockOutInput + " seconds");
        } else {
            alert("Invalid values entered. Number of login attempts and lock out time must be greater than 0.");
        }
    }
}

