import {
    Component, Inject,  Input, OnInit
} from '@angular/core';

import { AppSettingService } from "@/angular-blocworx/components/admin/app-setting/app-setting.service";


@Component({
    selector: 'languages-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/languages/languages-app-setting.html',

})

export class LanguagesAppSetting {

    @Input() languageData: any;
    @Input() languageNameData: any;
    @Input() main;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
    ) {

    }

    selectedLanguage: any;
    languageName: any;


    ngOnInit(): void {
    }


    async setAppSetting(settingKey, settingValue) {
        let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
        await this.appSettingService.setAppSetting(cartolyticsCustomerId, settingKey, settingValue);
    }

    updateItemTwo(selectedLanguage: string) {
        switch(selectedLanguage) {
            case 'en_EN':
                this.languageNameData = 'English';
                break;
            case 'fr_FR':
                this.languageNameData = 'Français';
                break;
            case 'hr':
                this.languageNameData = 'Hrvatski';
                break;
            case 'de_DE':
                this.languageNameData = 'Deutsch';
                break;
            case 'pt_BR':
                this.languageNameData = 'Português';
                break;
            case 'sv_SE':
                this.languageNameData = 'Svenska';
                break;
            case 'pl':
                this.languageNameData = 'Polski';
                break;
            default:
                this.languageNameData = 'English';
                break;
        }
    }
}
