import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { HttpHeaders } from '@angular/common/http';


export class LegacyFeaturesService {
    constructor() { }


}
