import {Component, Inject, OnInit, Input } from '@angular/core';

@Component({
    selector: 'html-field-in-data',
    templateUrl: 'angular-blocworx/components/fields/html/templates/in-data.html',
    styleUrls: ['angular-blocworx/components/fields/html/html.component.scss'],
})

export class HtmlFieldInData implements OnInit {

    @Input() field;
    @Input() row;
    @Input() scanStation;

    ngOnInit(): void {

    }

}
