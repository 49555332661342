import {
    Component, Inject,  Input, OnInit
} from '@angular/core';

import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";

@Component({
    selector: 'schedulers-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/schedulers/schedulers-app-setting.html',

})

export class SchedulersAppSetting {

    @Input() schedulersData: any;
    @Input() main;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
    ) {

    }

    turnOnSchedulers: any;

    ngOnInit(): void {
        if (this.turnOnSchedulers === '') {
            this.turnOnSchedulers = "0";
        }
    }

    async setAppSetting(settingKey, settingValue) {
        let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
        await this.appSettingService.setAppSetting(cartolyticsCustomerId, settingKey, settingValue);
    }
}
