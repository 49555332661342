import {
    Component, Inject,  Input, OnInit
} from '@angular/core';

import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";

@Component({
    selector: 'legacy-features-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/legacy-features/legacy-features-app-setting.html',

})

export class LegacyFeaturesAppSetting {

    @Input() legacyFeaturesData: any;
    @Input() main;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
    ) {

    }

    showLegacyFeatures: any;

    ngOnInit(): void {
        if (this.showLegacyFeatures === '') {
            this.showLegacyFeatures = "0";
        }
    }


    async setAppSetting(settingKey, settingValue) {
        let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
        await this.appSettingService.setAppSetting(cartolyticsCustomerId, settingKey, settingValue);
    }
}

