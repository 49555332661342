import { Component, OnInit, Injectable, Inject, Input, } from "@angular/core";
import { TemplateListService } from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list.service";
import { TemplateListEdit } from "@/angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list-edit.component";
import { OverlayContainer } from "@angular/cdk/overlay";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "template-list",
    templateUrl: "angular-blocworx/components/BlocSettings/manage-and-build-templates/template-list/template-list.html",
})

@Injectable({
    providedIn: "root",
})

export class TemplateList implements OnInit {

    @Input() scanStation;
    @Input() templateController;
    @Input() main;

    selectedTemplate: any;
    outputFileName: any;
    newTemplateName: string;
    primaryKey: any;
    outputFileValue: any;
    fileNameForTemplate: any;

    constructor(
        @Inject(TemplateListService) protected TemplateListService: TemplateListService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer,
    ) {
    }


    ngOnInit() {

    }

    /**
     *
     * Opens popup so user can edit template details
     * Passes variables to use in template-list-edit.component.ts
     * When popup closes, it updates template details
     *
     */
    openDialog() {
        const dialogRef = this.dialog.open(TemplateListEdit, {
            data: {
                selectedTemplate: this.selectedTemplate,
                newTemplateName: this.newTemplateName,
                templateController: this.templateController,
                scanStation: this.scanStation,
                primaryKey: this.primaryKey,
                outputFileValue: this.outputFileValue,
                fileNameForTemplate: this.fileNameForTemplate,
            },
        });
        this.overlayContainer.getContainerElement().classList.add("blocworx-popup");

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.newTemplateName && result.newTemplateName.trim() !== "") {
                const template = this.templateController.templateFileList.find(templateFile => templateFile.id === this.primaryKey);
                if (template) {
                    template.template_name = result.newTemplateName;
                }
            }
            if (result) {
                const template = this.templateController.templateFileList.find(templateFile => templateFile.id === this.primaryKey);
                if (template) {
                    template.output_filename = result.newOutputFileName;
                    template.exported_filename = result.fileNameForTemplate;
                }
            }
            this.overlayContainer.getContainerElement().classList.remove("blocworx-popup");
        });
    }

    getPrimaryKey(primaryKey: any) {
        this.primaryKey = primaryKey;
    }

    getFileName() {
        return this.templateController.downloadFactory.filename;
    }

    /**
     *
     * Downloads the template when user clicks a template from the list
     *
     * @param scanStationId
     * @param chosenTemplateFile
     */
    async templateDownloadMock(scanStationId, chosenTemplateFile): Promise<void> {
        try {
            const blob = await this.TemplateListService.templateDownloadMock(scanStationId, chosenTemplateFile);

            let URL = window.URL || window.webkitURL;
            this.templateController.downloadFactory.downloadUrl = URL.createObjectURL(blob);
            this.templateController.downloadFactory.filename = chosenTemplateFile;

            let element = document.getElementById("downloadPdfBtn") as HTMLAnchorElement;
            if (element) {
                element.href = this.templateController.downloadFactory.downloadUrl;
                element.download = chosenTemplateFile;
                element.click();

            }

        } catch (error) {
            console.error("Error during template download:", error);
        }
    }

    /**
     *
     * Deletes a template when user clicks X beside the templates name
     *
     * @param templateId
     * @param scanStationId
     */
    async deleteTemplate(templateId, scanStationId) {
        try {
            await this.TemplateListService.deleteTemplate(templateId);
            this.templateController.templateFileList = null;
            this.templateController.templateFileList = await this.TemplateListService.loadScanStationTemplates(scanStationId);

        } catch (e) {
            console.error(e);
        }
    }

    selectTemplate(template_name: any) {
        this.selectedTemplate = template_name;
    }

    getOutputFileValue(output_filename: any) {
        this.outputFileValue = output_filename;
    }

    getFileNameForTemplate(exported_filename) {
        this.fileNameForTemplate = exported_filename;
    }

}







