import { Component, OnInit, Injectable, Optional, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {AutocompleteSearchService} from "@/angular-blocworx/components/autocompleteSearch/autocomplete.service";

@Component({
    selector: 'autocomplete-search',
    templateUrl: 'angular-blocworx/components/autocompleteSearch/autocomplete-search.html',
    styleUrls: ['angular-blocworx/components/autocompleteSearch/autocomplete-search.scss']
})

@Injectable({
    providedIn: 'root'
})

export class AutocompleteSearch implements OnInit {

    @Input() scanStation;
    autocompleteSearchForm = new FormControl('');
    filteredOptions: Observable<any[]>;

    constructor(@Inject(AutocompleteSearchService) private autocompleteSearchService: AutocompleteSearchService) {}

    ngOnInit() {
        // when the value of the autocomplete search changes the search will take place
        this.filteredOptions = this.autocompleteSearchForm.valueChanges.pipe(

            debounceTime(250),
            switchMap(value => this._filter(value)),
        );
    }

    /**
     * This is the main function to perform the look up
     * @param value
     * @private
     */
    private async _filter(value: string): Promise<any[]> {
        if(value != "" && this.scanStation.specificFieldSearchModel != null) {

            this.scanStation.specificFieldSearchValue = value;

            return [];

            if(this.scanStation.scanStationObj.stationDetails.preventAutoSuggestSearch != 1) {
                let data = await this.autocompleteSearchService.lookUpValueForAutoCompleteSearch(value, this.scanStation.id, this.scanStation.jobID, this.scanStation.specificFieldSearchModel);
                return data.data;
            }


        } else {
            return [];
        }
    }
}
