import {
    Component, Inject,  Input, OnInit
} from '@angular/core';

import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";


@Component({
    selector: 'sign-in-google-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/sign-in-google/sign-in-google-app-setting.html',

})

export class SignInGoogleAppSetting {

    @Input() googleSignInData: any;
    @Input() googleCheckedData: any;
    @Input() main;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
    ) {}

    isChecked: any;
    googleEmail: any;

    ngOnInit(): void {

    }

    async setAppSetting(settingKey, settingValue) {
        let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
        await this.appSettingService.setAppSetting(cartolyticsCustomerId, settingKey, settingValue);
    }

    async setAppSettingCheckbox(settingKey: string, isChecked: boolean) {
        let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
        this.isChecked = this.isChecked ? '1' : '0';
        await this.appSettingService.setAppSetting(cartolyticsCustomerId, settingKey, isChecked);
    }

    showAlert() {
        alert("Sign in with Google created successfully");
    }
}
