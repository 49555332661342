import { Injectable, Inject } from "@angular/core";
import angular from "angular";
import { Configuration } from "@/angular-blocworx/components/configuration/services/configuration.service";
import { RequestService } from "@/angular-blocworx/components/configuration/services/request.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})

export class DeleteAllDataService {

    protected apiUrl: string;

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService,
    ) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
    }

    // HTTP Requests
    public deleteAllData = async (scanStationId: string, password: string, confirmationPhrase: string, userID: number): Promise<any> => {
        let headers = this.request.getHeaders();
        let params = {
            scanStationId,
            password,
            confirmationPhrase,
            userID,
        };
        let response = this.http.post(`${this.apiUrl}delete-data-scan-station`, params, { headers });
        return lastValueFrom(response)
    };

}
