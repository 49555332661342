import {
    Component, Inject,  Input, OnInit
} from '@angular/core';

import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";

@Component({
    selector: 'evervault-app-setting',
    templateUrl: 'angular-blocworx/components/admin/app-setting/evervault/evervault-app-setting.html',
})

export class EvervaultAppSetting {

    @Input() evervaultUrlData: any;
    @Input() evervaultFieldSlugsData: any;
    @Input() evervaultTokenData: any;
    @Input() main;

    constructor(
        @Inject(AppSettingService) private appSettingService: AppSettingService,
    ) {

    }

    evervaultUrl: string = '';
    evervaultFieldSlugs: string = ''
    evervaultBasicToken: string = ''

    ngOnInit(): void {
    }

    async setAppSetting(settingKey, settingValue) {
        let cartolyticsCustomerId = JSON.parse(localStorage.getItem('cartolyticsCustomerID'));
        await this.appSettingService.setAppSetting(cartolyticsCustomerId, settingKey, settingValue);
    }
}
